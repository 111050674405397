/* eslint-disable no-undef */
/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */

let timer;

(function () {
  var lastTime = 0;
  var vendors = ['ms', 'moz', 'webkit', 'o'];
  for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
    window.cancelAnimationFrame =
      window[vendors[x] + 'CancelAnimationFrame'] ||
      window[vendors[x] + 'CancelRequestAnimationFrame'];
  }

  if (!window.requestAnimationFrame)
    window.requestAnimationFrame = function (callback, element) {
      var currTime = new Date().getTime();
      var timeToCall = Math.max(0, 16 - (currTime - lastTime));
      var id = window.setTimeout(function () {
        callback(currTime + timeToCall);
      }, timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };

  if (!window.cancelAnimationFrame)
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id);
    };
})();

var Nodes = {
  // Settings
  density: 12,
  baseRadiusOptions: [3, 5, 8], // we put the size of the circles in a separate variable
  largeRadiusProbability: 0.7,
  mediumRadiusProbability: 0.5,

  reactionSensitivity: 3,
  lineThickness: 1,

  points: [],
  mouse: { x: -1000, y: -1000, down: false },

  animation: null,

  canvas: null,
  context: null,

  isDraw: true,

  bgImage: null,
  bgCanvas: null,
  bgContext: null,
  bgContextPixelData: null,

  maxRenderWidth: 1025,
  width1280: 1280,

  init: function () {
    // Settings visual canvas
    this.canvas = document.getElementById('canvas');
    this.context = canvas.getContext('2d');
    this.context.globalCompositeOperation = 'lighter';
    this.canvas.width = canvas.offsetWidth;
    this.canvas.height = window.innerHeight / 2; // we adjust the canvas height
    this.canvas.style.display = 'block';

    this.canvas.addEventListener('mousemove', this.mouseMove, false);
    this.canvas.addEventListener('mousedown', this.mouseDown, false);
    this.canvas.addEventListener('mouseup', this.mouseUp, false);
    this.canvas.addEventListener('mouseout', this.mouseOut, false);

    this.canvas.addEventListener('wheel', (event) => {
      if (this.canvas.width > this.width1280) {
        event.preventDefault();

        const delta = event.deltaY;

        if (delta > 0) {
          setTimeout(function () {
            Nodes.isDraw = false;
          }, 950);
        }
      }
    });

    window.onresize = function (event) {
      this.canvas.width = canvas.offsetWidth;
      this.canvas.height = window.innerHeight / 2;
      Nodes.onWindowResize();
    };

    const image = new Image();
    image.src = '/wp-content/themes/frank/assets/img/frank-canvas.png';
    if (this.canvas.width > 0 && this.canvas.height > 0) {
      this.loadData(image.src);
    } else {
      return;
    }
  },

  preparePoints: function () {
    // Clear points
    this.points = [];

    var width, height, i, j;
    var colors = this.bgContextPixelData.data;

    for (i = 0; i < this.canvas.height; i += this.density) {
      for (j = 0; j < this.canvas.width; j += this.density) {
        var pixelPosition = (j + i * this.bgContextPixelData.width) * 4;

        // Dont use white pixels
        if (
          (colors[pixelPosition] > 200 &&
            colors[pixelPosition + 1] > 200 &&
            colors[pixelPosition + 2] > 200) ||
          colors[pixelPosition + 3] === 0
        ) {
          continue;
        }

        // Generate a random radius from the baseRadiusOptions array
        var baseRadiusIndex = Math.floor(
          Math.random() * this.baseRadiusOptions.length,
        );
        var baseRadius = this.baseRadiusOptions[baseRadiusIndex];

        // Control the number of each point
        if (baseRadius === this.baseRadiusOptions[2]) {
          // big points
          if (Math.random() < 0.95) {
            // 95% chance of generating a large dot
            this.points.push({
              x: j,
              y: i,
              originalX: j,
              originalY: i,
              baseRadius: baseRadius,
            });
          }
        } else if (baseRadius === this.baseRadiusOptions[1]) {
          // medium points
          if (Math.random() < 0.8) {
            // 80% chance of generating a large dot
            this.points.push({
              x: j,
              y: i,
              originalX: j,
              originalY: i,
              baseRadius: baseRadius,
            });
          }
        } else {
          // small points
          this.points.push({
            x: j,
            y: i,
            originalX: j,
            originalY: i,
            baseRadius: baseRadius,
          });
        }
      }
    }
  },

  updatePoints: function () {
    var i, currentPoint, theta, distance;

    for (i = 0; i < this.points.length; i++) {
      currentPoint = this.points[i];

      theta = Math.atan2(
        currentPoint.y - this.mouse.y,
        currentPoint.x - this.mouse.x,
      );

      if (this.mouse.down) {
        distance =
          (this.reactionSensitivity * 200) /
          Math.sqrt(
            (this.mouse.x - currentPoint.x) * (this.mouse.x - currentPoint.x) +
              (this.mouse.y - currentPoint.y) * (this.mouse.y - currentPoint.y),
          );
      } else {
        distance =
          (this.reactionSensitivity * 100) /
          Math.sqrt(
            (this.mouse.x - currentPoint.x) * (this.mouse.x - currentPoint.x) +
              (this.mouse.y - currentPoint.y) * (this.mouse.y - currentPoint.y),
          );
      }

      currentPoint.x +=
        Math.cos(theta) * distance +
        (currentPoint.originalX - currentPoint.x) * 0.05;
      currentPoint.y +=
        Math.sin(theta) * distance +
        (currentPoint.originalY - currentPoint.y) * 0.05;
    }
  },

  drawPoints: function () {
    var i, currentPoint;

    for (i = 0; i < this.points.length; i++) {
      currentPoint = this.points[i];

      // Drawing a point
      const gradient = this.context.createRadialGradient(
        currentPoint.x,
        currentPoint.y,
        0,
        currentPoint.x,
        currentPoint.y,
        currentPoint.baseRadius,
      );

      gradient.addColorStop(0, 'rgba(190, 199, 251, 1)');
      gradient.addColorStop(1, 'rgba(190, 199, 251, 0)');
      this.context.fillStyle = gradient;

      this.context.beginPath();
      this.context.arc(
        currentPoint.x,
        currentPoint.y,
        currentPoint.baseRadius,
        0,
        Math.PI * 2,
        true,
      );

      this.context.closePath();
      this.context.fill();

      if (currentPoint.baseRadius === this.baseRadiusOptions[2]) {
        const gradient = this.context.createRadialGradient(
          currentPoint.x,
          currentPoint.y,
          0,
          currentPoint.x,
          currentPoint.y,
          currentPoint.baseRadius * 10,
        );

        gradient.addColorStop(0, 'rgba(190, 199, 251, 0.05)');
        gradient.addColorStop(0.8, 'rgba(190, 199, 251, 0)');

        this.context.fillStyle = gradient;
        this.context.beginPath();
        this.context.arc(
          currentPoint.x,
          currentPoint.y,
          currentPoint.baseRadius * 10,
          0,
          2 * Math.PI,
        );
        this.context.closePath();
        this.context.fill();
      }
    }
  },

  draw: function () {
    this.animation = requestAnimationFrame(function () {
      Nodes.draw();
    });

    if (this.isDraw) {
      this.clear();
      this.updatePoints();
      this.drawPoints();
    }
  },

  clear: function () {
    this.canvas.width = this.canvas.width;
  },

  // The file reader has uploaded the image. Add it to the graphic object that will be drawn
  loadData: function (data) {
    this.bgImage = new Image();
    this.bgImage.src = data;

    this.bgImage.onload = function () {
      Nodes.drawImageToBackground();
    };
  },

  // The image has been uploaded... We draw a background on the canvas
  drawImageToBackground: function () {
    this.bgCanvas = document.createElement('canvas');
    this.bgCanvas.width = this.canvas.width;
    this.bgCanvas.height = this.canvas.height;

    var newWidth, newHeight;

    // If the image is too big for the screen... scale it down.
    if (
      this.bgImage.width > this.bgCanvas.width - 100 ||
      this.bgImage.height > this.bgCanvas.height - 100
    ) {
      var maxRatio = Math.max(
        this.bgImage.width / (this.bgCanvas.width - 100),
        this.bgImage.height / (this.bgCanvas.height - 100),
      );
      newWidth = this.bgImage.width / maxRatio;
      newHeight = this.bgImage.height / maxRatio;
    } else {
      newWidth = this.bgImage.width;
      newHeight = this.bgImage.height;
    }

    // Draw the image on the canvas
    this.bgContext = this.bgCanvas.getContext('2d');

    this.bgContext.drawImage(
      this.bgImage,
      this.canvas.width / 2 - newWidth / 2,
      this.canvas.height / 2 - newHeight / 2,
      newWidth,
      newHeight,
    );

    this.bgContextPixelData = this.bgContext.getImageData(
      0,
      0,
      this.bgCanvas.width,
      this.bgCanvas.height,
    );

    this.preparePoints();
    this.draw();
    Nodes.isDraw = false;
  },

  mouseDown: function (event) {
    Nodes.mouse.down = true;
  },

  mouseUp: function (event) {
    Nodes.mouse.down = false;
  },

  mouseMove: function (event) {
    Nodes.isDraw = true;

    clearTimeout(timer);
    // console.log(timer);
    timer = setTimeout(function () {
      // console.log('isDraw', Nodes.isDraw);
      Nodes.isDraw = false;
    }, 5000);

    Nodes.mouse.x = event.offsetX || event.layerX - Nodes.canvas.offsetLeft;
    Nodes.mouse.y = event.offsetY || event.layerY - Nodes.canvas.offsetTop;
  },

  mouseOut: function (event) {
    Nodes.mouse.x = -1000;
    Nodes.mouse.y = -1000;
    Nodes.mouse.down = false;
  },

  // When changing the screen size, redraw the canvas

  onWindowResize: function () {
    if (window.innerWidth >= this.maxRenderWidth) {
      cancelAnimationFrame(this.animation);
      this.drawImageToBackground();
    } else {
      cancelAnimationFrame(this.animation);
    }
  },
};

setTimeout(function () {
  Nodes.init();
}, 10);
